import {getSessionId} from '../utilities/useSessionId'

export const createNewLead = async (api, customerId) => {
  const {
    data: {data},
  } = await api.post(`/customers/${customerId}/leads`, {
    source_id: 1, // Digital Retail
    customer_id: customerId,
  })

  return data
}

export const pushToCrm = (a2zCustomerApi, leadVehicleId) => {
  return a2zCustomerApi.post(`/customers/leads/pushToCrm/${leadVehicleId}`, {
    session_id: getSessionId(),
  })
}

export const getDrLeadFromResponse = (data) =>
  data?.included?.find(
    ({type, attributes: {source_id: sourceId}}) => type === 'leads' && sourceId === 1
  )

export const emailLeadVehicle = (
  a2zApi,
  leadVehicleId,
  additionalRecipients = [],
  unsendEmail = ''
) =>
  a2zApi.get(`/customers/leads/vehicles/${leadVehicleId}/email`, {
    params: {additionalRecipients, unsendEmail},
  })

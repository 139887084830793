import React, {isValidElement} from 'react'
import {renderToStaticMarkup} from 'react-dom/server'
import {useLocation} from 'react-router'
import {PROGRESS_STATE, PROGRESS_STEP} from 'Src/contexts/ProgressContext'

export const isEmpty = (value) => [null, undefined, ''].includes(value)

/**
 * @returns {boolean}
 */
export const getIsProduction = () => process.env.NODE_ENV === 'production'

export const getCurrentlyStartedProgressStep = (progress, dealType) => {
  return Object.keys(progress).find((key) => {
    if (key === PROGRESS_STEP.products) {
      return progress.products[dealType] === PROGRESS_STATE.started
    }

    return progress[key] === PROGRESS_STATE.started
  })
}

export const getStepProgress = (progressStep) => {
  return [PROGRESS_STEP.payment, PROGRESS_STEP.uploads].includes(progressStep)
    ? PROGRESS_STATE.complete
    : PROGRESS_STATE.untouched
}

export const handleProductBreadcrumbTracking = (progress, destination, dealType, progressStep) => {
  if (!destination && !progressStep) {
    return {}
  }

  const isArrivingAtProducts = destination === PROGRESS_STEP.products

  if (isArrivingAtProducts) {
    const step = progressStep ? {[progressStep]: getStepProgress(progressStep)} : {}

    return {
      ...step,
      products: {...progress.products, [dealType]: PROGRESS_STATE.started},
    }
  }

  const isLeavingProducts = progressStep === PROGRESS_STEP.products

  if (isLeavingProducts) {
    return {
      products: {...progress.products, [dealType]: PROGRESS_STATE.complete},
      [destination]: PROGRESS_STATE.started,
    }
  }
}

export const breadcrumbProgressTracking = (progress, destination, dealType) => {
  const progressStep = getCurrentlyStartedProgressStep(progress, dealType)

  if ([progressStep, destination].includes(PROGRESS_STEP.products)) {
    return handleProductBreadcrumbTracking(progress, destination, dealType, progressStep)
  }

  if (
    (destination === PROGRESS_STEP.finalize && progress.finalize === PROGRESS_STATE.complete) ||
    (destination === PROGRESS_STEP.trade && progress.trade === PROGRESS_STATE.complete)
  ) {
    return {
      [progressStep]: getStepProgress(progressStep),
      [destination]: PROGRESS_STATE.complete,
    }
  }

  if (progressStep === destination || !progressStep) {
    return {[destination]: PROGRESS_STATE.started}
  }

  if ([PROGRESS_STEP.payment, PROGRESS_STEP.uploads].includes(progressStep)) {
    return {[progressStep]: PROGRESS_STATE.complete, [destination]: PROGRESS_STATE.started}
  }

  if (destination === undefined) {
    return {[progressStep]: PROGRESS_STATE.untouched}
  }

  return {[progressStep]: PROGRESS_STATE.untouched, [destination]: PROGRESS_STATE.started}
}

export const getBreadcrumbProgressState = (dealType, progress, type) => {
  if (type === PROGRESS_STEP.products) {
    return progress.products[dealType] ?? PROGRESS_STATE.untouched
  }

  return progress[type]
}

export const handleProductBackButtonBreadcrumbInteraction = (
  dealType,
  previousStep,
  productsProgress,
  currentlyStartedProgressStep
) => {
  const productsDealTypeComplete = {...productsProgress, [dealType]: PROGRESS_STATE.complete}
  const productsDealTypeStarted = {...productsProgress, [dealType]: PROGRESS_STATE.started}

  if ([PROGRESS_STEP.payment, PROGRESS_STEP.credit, PROGRESS_STEP.trade].includes(previousStep)) {
    return {
      [previousStep]: PROGRESS_STATE.started,
      products: productsDealTypeComplete,
    }
  }

  if (previousStep === PROGRESS_STEP.documentUpload) {
    return {
      [PROGRESS_STEP.uploads]: PROGRESS_STATE.started,
      products: productsDealTypeComplete,
    }
  }

  if (previousStep === PROGRESS_STEP.products && currentlyStartedProgressStep) {
    return {
      [currentlyStartedProgressStep]: PROGRESS_STATE.untouched,
      products: productsDealTypeStarted,
    }
  }

  if (!currentlyStartedProgressStep) {
    return {
      products: productsDealTypeStarted,
    }
  }

  return {
    products: productsDealTypeComplete,
  }
}

const handleNonProductBackButtonInteraction = (currentlyStartedStep, previousStep) => {
  if (currentlyStartedStep === previousStep) {
    return {
      [currentlyStartedStep]: PROGRESS_STATE.started,
    }
  }

  if (previousStep === PROGRESS_STEP.documentUpload) {
    return !currentlyStartedStep
      ? {[PROGRESS_STEP.uploads]: PROGRESS_STATE.started}
      : {
          [PROGRESS_STEP.uploads]: PROGRESS_STATE.started,
          [currentlyStartedStep]: PROGRESS_STATE.untouched,
        }
  }

  return !currentlyStartedStep
    ? {[previousStep]: PROGRESS_STATE.started}
    : {
        [previousStep]: PROGRESS_STATE.started,
        [currentlyStartedStep]: PROGRESS_STATE.untouched,
      }
}

const getPreviousProgressStep = (history) => {
  const lastPage = history.entries[history.index].pathname

  return lastPage === '/' ? PROGRESS_STEP.payment : lastPage?.replace('/', '')
}

export const backButtonBreadcrumbInteraction = (history, progress, dealType) => {
  const currentlyStartedStep = getCurrentlyStartedProgressStep(progress, dealType)
  const previousStep = getPreviousProgressStep(history)

  if (!previousStep) {
    return {}
  }

  if ([previousStep, currentlyStartedStep].includes(PROGRESS_STEP.products)) {
    return handleProductBackButtonBreadcrumbInteraction(
      dealType,
      previousStep,
      progress.products,
      currentlyStartedStep
    )
  }

  return handleNonProductBackButtonInteraction(currentlyStartedStep, previousStep)
}

// if these zipcodes change, then also change them in: MarketScan.php collectMarketCodesByZip, A2Z helpers.js
export const stateFromZip = (z) => {
  z = parseInt(z, 10) // removes leading '0'

  if (z < 1001 || z > 99950) {
    return {stateId: null, stateName: null}
  }

  let i = 70
  let next
  const zs = [
    [1001, 2791, 'Massachusetts', 34],
    [2801, 2940, 'Rhode Island', 40],
    [3031, 3897, 'New Hampshire', 24],
    [3901, 4992, 'Maine', 20],
    [5001, 5495, 'Vermont', 46],
    [5501, 5544, 'Massachusetts', 34],
    [5601, 5907, 'Vermont', 46],
    [6001, 6389, 'Connecticut', 7],
    [6390, 6390, 'New York', 27],
    [6401, 6928, 'Connecticut', 7],
    [7001, 8989, 'New Jersey', 25],
    [10001, 14975, 'New York', 27],
    [15001, 19640, 'Pennsylvania', 39],
    [19701, 19980, 'Delaware', 8],
    [20001, 20039, 'Dist. of Columbia', 9],
    [20040, 20167, 'Virginia', 47],
    [20042, 20599, 'Dist. of Columbia', 9],
    [20331, 20331, 'Maryland', 33],
    [20335, 20797, 'Maryland', 33],
    [20799, 20799, 'Dist. of Columbia', 9],
    [20812, 21930, 'Maryland', 33],
    [22001, 24658, 'Virginia', 47],
    [24701, 26886, 'West Virginia', 49],
    [27006, 28909, 'North Carolina', 28],
    [29001, 29948, 'South Carolina', 41],
    [30001, 31999, 'Georgia', 11],
    [32004, 34997, 'Florida', 10],
    [35004, 36925, 'Alabama', 1],
    [37010, 38589, 'Tennessee', 43],
    [38601, 39776, 'Mississippi', 37],
    [39901, 39901, 'Georgia', 11],
    [40003, 42788, 'Kentucky', 18],
    [43001, 45999, 'Ohio', 30],
    [46001, 47997, 'Indiana', 15],
    [48001, 49971, 'Michigan', 35],
    [50001, 52809, 'Iowa', 16],
    [53001, 54990, 'Wisconsin', 50],
    [55001, 56763, 'Minnesota', 36],
    [57001, 57799, 'South Dakota', 42],
    [58001, 58856, 'North Dakota', 29],
    [59001, 59937, 'Montana', 21],
    [60001, 62999, 'Illinois', 14],
    [63001, 65899, 'Missouri', 38],
    [66002, 67954, 'Kansas', 17],
    [68001, 68118, 'Nebraska', 22],
    [68119, 68120, 'Iowa', 16],
    [68122, 69367, 'Nebraska', 22],
    [70001, 71232, 'Louisiana', 19],
    [71233, 71233, 'Mississippi', 37],
    [71234, 71497, 'Louisiana', 19],
    [71601, 72959, 'Arkansas', 4],
    [73001, 73199, 'Oklahoma', 31],
    [73301, 73301, 'Texas', 44],
    [73401, 74966, 'Oklahoma', 31],
    [75001, 75501, 'Texas', 44],
    [75502, 75502, 'Arkansas', 4],
    [75503, 79999, 'Texas', 44],
    [80001, 81658, 'Colorado', 6],
    [82001, 83128, 'Wyoming', 51],
    [83201, 83876, 'Idaho', 13],
    [84001, 84784, 'Utah', 45],
    [85001, 86556, 'Arizona', 3],
    [87001, 88441, 'New Mexico', 26],
    [88510, 88589, 'Texas', 44],
    [88901, 89883, 'Nevada', 23],
    [90001, 96162, 'California', 5],
    [96701, 96898, 'Hawaii', 12],
    [97001, 97920, 'Oregon', 32],
    [98001, 99403, 'Washington', 48],
    [99501, 99950, 'Alaska', 2],
  ]

  while (i) {
    next = zs[--i]

    if (z >= next[0] && z <= next[1]) {
      return {stateId: next[3], stateName: next[2]}
    }
  }

  return {stateId: null, stateName: null}
}

export const displayPhoneNumber = (phone) => {
  const cleaned = `${phone}`.replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('')
  }

  return `${phone}`
}

export const convertMbToBytes = (mb) => mb * 1024 * 1024

export const preventNonNumericalInput = (event) => {
  if (!event.key.match(/^\d$/)) {
    event.preventDefault()
  }
}

export const setDisabled = (element, bool) => element.current.setAttribute('disabled', bool)

export const FINALIZE_STEPS = ['check-availability', 'meet', 'schedule']

export const FindQuery = (searchFor) => new URLSearchParams(useLocation().search).get(searchFor)

// _______LANG BEGIN_______
// KEEP FUNCTIONS IN SYNC with a2zsync repo: resources/assets/js/components/helpers.jsx
const langErrors = {
  typeDictionary: 'Parameter "Replacement/Dictionary" must be of type object 😬',
  dot: 'Parameter "Key" must include a "." 😬',
  string: 'Parameter "Key" must be of type string 😬',
  replaceMissing: "The key's value doesn't include a provided replacement 😬",
  replaceNotSupported: "The key's value doesn't support replacements 😬",
}

const isNotDictionary = (item) => Object.prototype.toString.call(item) !== '[object Object]'

const langFindSubKey = (keyString, lang) => {
  if (!keyString.includes('.')) {
    throw new Error(langErrors.dot)
  }

  const keys = keyString.split('.')

  return keys.reduce((resultKey, currentKey) => {
    if (lang[currentKey] === '') {
      lang = currentKey
    } else if (lang[currentKey]) {
      lang = lang[currentKey]
    } else {
      return keyString
    }

    resultKey = lang

    return resultKey
  }, '')
}

const replaceJSX = (jsxStringArray, find, replace) => {
  let returnArray = []
  jsxStringArray.forEach((item) => {
    if (typeof item === 'string' && item.includes(find)) {
      const splitArray = item.split(find)
      splitArray.splice(1, 0, replace)
      returnArray = returnArray.concat(splitArray)

      return
    }

    returnArray.push(item)
  })

  return returnArray
}

const jsxStringArrayIncludesNeedle = (jsxStringArray, needle) =>
  jsxStringArray.some((item) => (typeof item === 'string' ? item.includes(needle) : false))

const langFindKey = (key, lang) => (lang[key] === '' ? key : (lang[key] ?? key))

const getUniqueKeyForJsx = (item, index) => {
  if (isValidElement(item)) {
    return `${index}-${item.props?.text?.toString().length}`
  }

  return `${index}-${item.length}`
}

const langMakeReplacementsAsJsx = (result, replacements) => {
  // create array so to account for mix of jsx and string elements from map
  result = [result]
  Object.keys(replacements).forEach((key) => {
    const needle = `:${key}`

    if (!jsxStringArrayIncludesNeedle(result, needle)) {
      throw new Error(langErrors.replaceMissing)
    }

    // a while loop is used here because jest & node don't support replaceAll yet
    // https://stackoverflow.com/questions/65295584/jest-typeerror-replaceall-is-not-a-function
    while (jsxStringArrayIncludesNeedle(result, needle)) {
      result = replaceJSX(result, needle, replacements[key])
    }
  })

  return (
    <>
      {result.map((item, index) => (
        <React.Fragment key={getUniqueKeyForJsx(item, index)}>{item}</React.Fragment>
      ))}
    </>
  )
}

const langMakeReplacements = (result, replacements) => {
  Object.keys(replacements).forEach((key) => {
    const needle = `:${key}`

    if (!result.includes(needle)) {
      throw new Error(langErrors.replaceMissing)
    }

    // a while loop is used here because jest & node don't support replaceAll yet
    // https://stackoverflow.com/questions/65295584/jest-typeerror-replaceall-is-not-a-function
    while (result.includes(needle)) {
      result = result.replace(needle, replacements[key])
    }
  })

  return result
}

/**
 * Returns a translation as either a string or JSX.
 * @param {string} key
 * @param {Object | undefined} replacements
 * @param {Object | undefined} dictionary
 * @returns {string | React.ReactElement}
 */
export const trans = (
  key,
  replacements = undefined,
  dictionary = typeof window !== 'undefined' && 'A2Z_LANG' in window ? window.A2Z_LANG : {}
) => {
  if (typeof key !== 'string') {
    throw new Error(langErrors.string)
  }

  if (isNotDictionary(dictionary) || (replacements && isNotDictionary(replacements))) {
    throw new Error(langErrors.typeDictionary)
  }

  let result = key.includes('.') ? langFindSubKey(key, dictionary) : langFindKey(key, dictionary)

  if (replacements) {
    if (!result.includes(':')) {
      throw new Error(langErrors.replaceNotSupported)
    }

    const isJSX = Object.keys(replacements).some((item) => isValidElement(replacements[item]))

    if (isJSX) {
      return langMakeReplacementsAsJsx(result, replacements)
    }

    result = langMakeReplacements(result, replacements)
  }

  const hasHtmlTags = /<.*?>/.test(result)

  if (hasHtmlTags) {
    return <>{createJSX(getNodes(result))}</>
  }

  return result
}

/**
 * Returns a translation as a string. Necessary for situations where TypeScript only accepts string values.
 * @param {string} key
 * @param {Object | undefined} replacements
 * @param {Object | undefined} dictionary
 * @returns {string}
 */
export const transString = (...args) => {
  const transNode = trans(...args)

  if (typeof transNode === 'string') {
    return transNode
  }

  const div = document.createElement('div')
  div.innerHTML = renderToStaticMarkup(transNode)

  return div.textContent || div.innerText || ''
}

const getNodes = (str) => new DOMParser().parseFromString(str, 'text/html').body.childNodes

const createJSX = (nodeList) =>
  Array.from(nodeList).map((node, index) => {
    const {localName, childNodes, nodeValue} = node
    const key = `${localName}-${index}`

    return localName
      ? React.createElement(
          localName,
          {key},
          childNodes && childNodes.length ? createJSX(childNodes) : null
        )
      : nodeValue
  })
// _______LANG END_______

export {langErrors} // export for testing
